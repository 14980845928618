import { Container, Flex, Heading, List, ListItem, Text } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { GalleryCard } from '../components/GalleryCard';
import { Layout } from '../components/Layout';
import { QuestionCard } from '../components/QuestionCard';
import '../global.css';
import { GalleriesQueryResult } from '../types';

interface Props {
    data: GalleriesQueryResult;
}

const Page: FC<Props> = ({ data }) => {
    return (
        <Layout title="Bramy, ogrodzenia, konstrukcje ze stali nierdzewnej">
            <Container alignSelf="center" maxW={['container.sm', 'container.xl']} px={['4', '10']} mt={['4', '12']}>
                <Flex gap="10" direction="column">
                    <Heading
                        id="realizacje"
                        scrollMarginTop="4"
                        mx={['12', '0']}
                        as="h2"
                        size="2xl"
                        variant="secondary"
                        alignSelf="flex-start"
                    >
                        Bramy, ogrodzenia i&nbsp;inne konstrukcje stalowe
                    </Heading>

                    <GalleryCard fileEdges={data.kons.edges} id="realizacje-konstrukcje" title="Konstrukcje stalowe" />

                    <QuestionCard defaultExpanded title="Co wyróżnia nasze konstrukcje?">
                        <Heading as="h4" size="md">
                            Bramy wjazdowe na posesje
                        </Heading>
                        <Text mt={['2']}>
                            Oferujemy wykonanie bram wjazdowych zarówno automatycznych, jak i tradycyjnych. W doborze
                            materiału na bramę uwzględniamy wpływ czynników atmosferycznych, co zapewnia bramom trwałość
                            i bezpieczeństwo. Automatyczne bramy wjazdowe zapewniają szybkie i wygodne otwieranie oraz
                            zamykanie, co znacznie ułatwia codzienne życie. Natomiast tradycyjne bramy wjazdowe
                            charakteryzują się klasycznym wyglądem, który idealnie wpisuje się w każdą architekturę. Bez
                            względu na to, jaki rodzaj bramy wybierzesz, możesz być pewny, że otrzymasz produkt
                            najwyższej jakości, który zadowoli nawet najbardziej wymagających klientów.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Bramy garażowe
                        </Heading>
                        <Text mt={['2']}>
                            Bramy garażowe zapewnią bezpieczeństwo i wygodę użytkowania Twojego garażu. Nasze bramy to
                            nie tylko funkcjonalność, ale także estetyka i elegancki wygląd, który podkreśli charakter
                            Twojego domu. Dzięki naszym produktom będziesz miał pewność, że garaż jest dobrze
                            zabezpieczony.
                        </Text>

                        <Heading as="h4" size="md" mt={['6']}>
                            Rampy, podjazdy dla osób z niepełnosprawnościami
                        </Heading>
                        <Text mt={['2']}>
                            Nasze rampy i podjazdy są wykonane z trwałych materiałów i mogą być dostosowane do Twoich
                            indywidualnych potrzeb. Zapewnią łatwe i bezpieczne przejście do budynku, przejście przez
                            chodnik lub schody.
                        </Text>
                    </QuestionCard>
                </Flex>
            </Container>
        </Layout>
    );
};

export default Page;

/*
TODO:
* improve SEO (roobts.txt)
* play with the images sizes & formats (only jpg for full?)
* improve desktop gallery

*/
export const query = graphql`
    fragment MyGallery on FileConnection {
        edges {
            node {
                id
                name
                thumb: childImageSharp {
                    gatsbyImageData(height: 300, placeholder: BLURRED, layout: CONSTRAINED)
                }
                full: childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
        }
    }

    query KonsQuery {
        kons: allFile(filter: { relativeDirectory: { eq: "galleries/kons" } }) {
            ...MyGallery
        }
    }
`;
